// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedroom-js": () => import("./../../../src/pages/bedroom.js" /* webpackChunkName: "component---src-pages-bedroom-js" */),
  "component---src-pages-budget-calculator-js": () => import("./../../../src/pages/budgetCalculator.js" /* webpackChunkName: "component---src-pages-budget-calculator-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-design-3-d-js": () => import("./../../../src/pages/design3d.js" /* webpackChunkName: "component---src-pages-design-3-d-js" */),
  "component---src-pages-false-ceiling-advance-with-tray-vertical-js": () => import("./../../../src/pages/falseCeilingAdvanceWithTrayVertical.js" /* webpackChunkName: "component---src-pages-false-ceiling-advance-with-tray-vertical-js" */),
  "component---src-pages-false-ceiling-advance-with-wiring-separate-js": () => import("./../../../src/pages/falseCeilingAdvanceWithWiringSeparate.js" /* webpackChunkName: "component---src-pages-false-ceiling-advance-with-wiring-separate-js" */),
  "component---src-pages-false-ceiling-simple-calculator-js": () => import("./../../../src/pages/falseCeilingSimpleCalculator.js" /* webpackChunkName: "component---src-pages-false-ceiling-simple-calculator-js" */),
  "component---src-pages-false-ceiling-simple-with-tray-vertical-js": () => import("./../../../src/pages/falseCeilingSimpleWithTrayVertical.js" /* webpackChunkName: "component---src-pages-false-ceiling-simple-with-tray-vertical-js" */),
  "component---src-pages-false-ceiling-simple-with-wiring-and-tray-vertical-js": () => import("./../../../src/pages/falseCeilingSimpleWithWiringAndTrayVertical.js" /* webpackChunkName: "component---src-pages-false-ceiling-simple-with-wiring-and-tray-vertical-js" */),
  "component---src-pages-false-ceiling-simple-with-wiring-separate-js": () => import("./../../../src/pages/falseCeilingSimpleWithWiringSeparate.js" /* webpackChunkName: "component---src-pages-false-ceiling-simple-with-wiring-separate-js" */),
  "component---src-pages-falseceiling-calculator-estimates-js": () => import("./../../../src/pages/falseceilingCalculatorEstimates.js" /* webpackChunkName: "component---src-pages-falseceiling-calculator-estimates-js" */),
  "component---src-pages-falseceiling-complexcalculator-js": () => import("./../../../src/pages/falseceilingComplexcalculator.js" /* webpackChunkName: "component---src-pages-falseceiling-complexcalculator-js" */),
  "component---src-pages-falseceiling-js": () => import("./../../../src/pages/falseceiling.js" /* webpackChunkName: "component---src-pages-falseceiling-js" */),
  "component---src-pages-falseceilingcalculator-js": () => import("./../../../src/pages/falseceilingcalculator.js" /* webpackChunkName: "component---src-pages-falseceilingcalculator-js" */),
  "component---src-pages-falseceilingcostcalculator-js": () => import("./../../../src/pages/falseceilingcostcalculator.js" /* webpackChunkName: "component---src-pages-falseceilingcostcalculator-js" */),
  "component---src-pages-fcarea-calculator-js": () => import("./../../../src/pages/fcareaCalculator.js" /* webpackChunkName: "component---src-pages-fcarea-calculator-js" */),
  "component---src-pages-gypsumcost-js": () => import("./../../../src/pages/gypsumcost.js" /* webpackChunkName: "component---src-pages-gypsumcost-js" */),
  "component---src-pages-gypsumvspopeng-js": () => import("./../../../src/pages/gypsumvspopeng.js" /* webpackChunkName: "component---src-pages-gypsumvspopeng-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiordesign-js": () => import("./../../../src/pages/interiordesign.js" /* webpackChunkName: "component---src-pages-interiordesign-js" */),
  "component---src-pages-interiordesignenglish-js": () => import("./../../../src/pages/interiordesignenglish.js" /* webpackChunkName: "component---src-pages-interiordesignenglish-js" */),
  "component---src-pages-kitchen-calculator-js": () => import("./../../../src/pages/kitchenCalculator.js" /* webpackChunkName: "component---src-pages-kitchen-calculator-js" */),
  "component---src-pages-kitchen-js": () => import("./../../../src/pages/kitchen.js" /* webpackChunkName: "component---src-pages-kitchen-js" */),
  "component---src-pages-laminates-js": () => import("./../../../src/pages/laminates.js" /* webpackChunkName: "component---src-pages-laminates-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-spacesaving-js": () => import("./../../../src/pages/spacesaving.js" /* webpackChunkName: "component---src-pages-spacesaving-js" */),
  "component---src-pages-whattobuy-js": () => import("./../../../src/pages/whattobuy.js" /* webpackChunkName: "component---src-pages-whattobuy-js" */),
  "component---src-pages-woodwork-js": () => import("./../../../src/pages/woodwork.js" /* webpackChunkName: "component---src-pages-woodwork-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tag-posts-js": () => import("./../../../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

